/* globals __SENTRY_URL__, __SENTRY_ENV__, __SENTRY_NAME__, __SENTRY_WHITELIST__ */
import Raven from 'raven-js';

if (__SENTRY_URL__) {
  Raven.config(__SENTRY_URL__, {
    environment: __SENTRY_ENV__,
    serverName: __SENTRY_NAME__,
    ignoreErrors: [
      'Script error',
      'Network error'
    ],
    whitelistUrls: [new RegExp(__SENTRY_WHITELIST__)]
  }).install();

  if (window) {
    window.__Raven = Raven;
  }
}
